import { useWalletNfts } from "@nfteyez/sol-rayz-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl, Connection } from "@solana/web3.js";
import { useContext, useEffect, useState } from "react";
import { compile } from "sass";
import { getBurnedTokens, initOgBurn, processOgBurn } from "../../api/burn-nft.service";
import { getMyWhitelists, getMyMintOrders, initPreorder, processPreorder, getPreordersCount } from "../../api/preorders.service";
import { LoadingBars } from "../../components/LoadingBars";
import { InfoModal } from "../../components/modals/InfoModal";
import { NftCard } from "../../components/NftCard";
import { ToastCongrats } from "../../components/toasts/CongratsToast";
import { AudioContext } from "../../contexts/audio.context";
import { AuthorizationContext } from "../../contexts/auth.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { burnNFT, burnNFTs, burnTx, createBurnTx } from "../../services/burn.service";
import { preOrderCat } from "../../services/preorder.service";
import { getSolanaProvider } from "../../services/solana";
import { MINT_PRICE } from "../../utils/constants";
import './pre-order.scss'
import { confetti } from '../../utils/confetti';
import { NavBar } from "../../layout/components/nav-bar";
import { VideoBg } from "../../components/VideoBg";
import { ProgressBar } from "react-bootstrap";
import CountDownTimer from "../../components/CountDownTimer";
import { DateTime } from "luxon";

const URL = process.env.REACT_APP_RPC_URL ?? clusterApiUrl("devnet");
const SILLHOUTE = "https://i.imgur.com/1gMf6zk.png";
const SILLHOUTE_2 = "https://i.imgur.com/YWdR97x.png";
const SILLHOUTE_3 = "https://i.imgur.com/A7FltAi.png";
const BUTTON_SILVER = "https://i.imgur.com/go7GZ22.png";
const BUTTON_GOLD = "https://i.imgur.com/C6VhUqi.png";
const cats = [SILLHOUTE, SILLHOUTE_2, SILLHOUTE_3];

const MINT_TIME = DateTime.fromISO(`2022-07-19T20:00:00.000`, { zone: 'UTC' });

const MINT_INFO_LINK = "https://twitter.com/degenfatcats/status/1541876877912162306";

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const WlTypes = {
    OG_ROLE: 'OG_ROLE',
    WL_KEY: 'WL_KEY',
    PARTNER: 'PARTNER',
    COIN: 'COIN',
    FRACTIONALIZATION: 'FRACTIONALIZATION'
};

const getBadgeColor = (wlType: any) => {
    if (wlType === WlTypes.COIN) {
        return `bg-warning`;
    }
    if (wlType === WlTypes.OG_ROLE) {
        return `bg-primary`;
    }
    if (wlType === WlTypes.PARTNER) {
        return `bg-light`;
    }
    if (wlType === WlTypes.WL_KEY) {
        return `bg-success`;
    }
}

const PreOrderContainer = ({ wallet, state, preorders, onPreorder, onOpenPastMints, mintedCount, loading, buttonLoading }: any) => {
    const [amount, setAmount] = useState(1);
    const handleChangePrice = (e: any) => {
        e.preventDefault(); // prevent the default action
        setAmount(e.target.value); // set name to e.target.value (event)
    }

    return (
        <>
            <section className="position-relative pt-4 pt-sm-5 z-index-5">
                <div className="text-center my-3 mt-sm-4 mx-auto" style={{ maxWidth: `600px` }}>
                    <h1 className="mt-5 mb-1 text-light fancy-text main-text">
                        { DateTime.utc() < MINT_TIME ? "WL PRE" : "" } MINT DEGEN FAT CATS
                    </h1>
                    <h5 className="">9.99 SOL</h5>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="" style={{ maxWidth: '600px' }}>
                        <div className="key-special-card special-card special-card-user">
                            <div className="special-card-body">
                                <div className="author p-3">

                                    <img src={`https://i.imgur.com/8BrfsZT.png`} className="avatar selected" alt="..." />

                                    {
                                        !loading && wallet.connected && DateTime.utc() < MINT_TIME &&
                                        <h4 className="title fancy-text mb-0">
                                            You have <u>{state?.whitelists?.length}</u> WL Available
                                        </h4>
                                    }
                                    {
                                        !loading && wallet.connected && DateTime.utc() >= MINT_TIME &&
                                        <h4 className="title fancy-text mb-0">
                                            Public Mint Now Open
                                        </h4>
                                    }
                                    {
                                        loading && wallet.connected &&
                                        <h5 className="fst-italic mt-4">LOADING<small><span className="dot dot1">.</span><span className="dot dot2">.</span><span className="dot dot3">.</span></small></h5>
                                    }
                                    {/* {
                                        !wallet.connected &&
                                        <h4 className="text-center title fancy-text mb-0 mt-3">
                                            Connect Wallet to Enter
                                        </h4>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mx-auto my-1 my-sm-2" style={{ maxWidth: `600px` }}>
                    {
                        !loading && state?.whitelists?.map((wl: any) => (
                            <>
                                <span className={"badge me-1 badge-pill " + getBadgeColor(wl.type)}>
                                    {
                                        wl?.type === WlTypes.PARTNER && <>SPECIAL</>
                                    }
                                    {
                                        wl?.type !== WlTypes.PARTNER && <>
                                            {wl?.type?.split('_').join(' ')}
                                        </>
                                    }
                                </span>
                            </>
                        ))
                    }
                </div>

                {
                    !wallet.connected &&
                    <WalletMultiButton style={{ margin: 'auto' }} />
                }
                {
                    loading && wallet.connected &&
                    <div className="py-3">
                        <LoadingBars />
                    </div>
                }

                {
                    !loading && wallet.connected && <>
                        <div className="d-flex justify-content-center mt-4 mt-sm-3">
                            <div className="d-flex px-4 px-0">
                                <span className="d-inline-flex" style={{ maxWidth: '600px' }}>
                                    <h4 className="text-light fancy-text proverb">I am diamond fisting tf out of <input type="number" className="inp mx-0 mx-sm-2" placeholder="1"
                                        value={amount}
                                        onChange={handleChangePrice}
                                    /><br />life changing cat{amount > 1 ? 's' : ''} with an elite crew.<br /><br />Fuck off tellin me to sell, I'll fuckin die with {amount > 1 ? 'these cats' : 'this cat'} r u stupid.
                                        <br /><br />I used to risk my life for a bag, I'll ride this shit to 0 & do it again.
                                    </h4>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center pb-3">
                            <>
                                <div className="d-flex justify-content-center">
                                    {
                                        !buttonLoading &&
                                        <>
                                            <img onClick={() => {}} className={"double-button my-3 img-fluid premint-button cursor-pointer"} src={BUTTON_GOLD} alt="double or nothing" width="240px" height="100%" />

                                            {/* <button type="button" disabled={state?.whitelists?.length > +amount} className="btn btn-dark px-3 px-sm-4" onClick={() => onPreorder(+amount)}>
                                                {
                                                    state?.whitelists?.length > +amount &&
                                                    <>
                                                        <i className="me-2 fa-solid fa-heart-crack"></i>
                                                        <span className="">
                                                            Sorry degen, that's too many fat cats.
                                                        </span>
                                                    </>
                                                }
                                                {
                                                    state?.whitelists?.length <= +amount &&
                                                    <>
                                                        <i className="me-2 fa-solid fa-handshake-simple"></i>
                                                        <span className="">
                                                            Premint {amount} Fat Cat{amount > 1 ? 's' : ''} for {9.99 * (+amount)} SOL
                                                        </span>
                                                    </>
                                                }
                                            </button> */}
                                        </>
                                    }
                                    {
                                        buttonLoading &&
                                        <LoadingBars />
                                    }
                                </div>
                            </>
                        </div>
                        {/* <div className={"mx-auto " + (mintedCount > 0 ? "" : "d-none")} style={{ maxWidth: "240px" }}>
                            <ProgressBar animated className={"h-1rem my-0 rounded-0 rounded border-start border-end border border-sm"} variant="dark" now={mintedCount + 16215} max={20000} min={0} label={`${mintedCount + 16215} / 20000`} />
                        </div> */}
                        {
                            !buttonLoading && !!preorders?.filter((o: any) => o.wlType !== WlTypes.FRACTIONALIZATION)?.length &&
                            <div className="text-center mx-auto pb-2" style={{ maxWidth: `600px` }}>
                                <a onClick={onOpenPastMints} href="javascript:;" className="text-decoration-none"><h6 className="text-light">SEE REDEEMED WL ({preorders?.filter((o: any) => o.wlType !== WlTypes.FRACTIONALIZATION)?.length})</h6></a>
                            </div>
                        }
                    </>
                }
            </section>
        </>

    )
}

export const PreOrderPage = () => {
    const wallet = useWallet();
    const { style } = useContext(StyleThemeContext);
    const { auth, signIn, signInViaLedger } = useContext(AuthorizationContext);
    const { play } = useContext(AudioContext);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const handleInfoModalOpen = () => setShowInfoModal(true);
    const handleInfoModalClose = () => setShowInfoModal(false);

    const [loading, setLoading] = useState<any>(false);
    const enableLoading = () => setLoading(true);
    const disableLoading = () => setLoading(false);


    const [buttonLoading, setButtonLoading] = useState<any>(false);
    const enableButtonLoading = () => setButtonLoading(true);
    const disableButtonLoading = () => setButtonLoading(false);

    const [state, setState] = useState<any>();
    const [preorders, setPreorders] = useState<any>();
    const [mintedCount, setMintedCount] = useState<any>();

    const [walletCache, setWalletCache] = useState<any>(null);

    const [congratsToastShow, setCongratsToastShow] = useState<boolean>(false);

    useEffect(() => {
        if (wallet.connected && wallet?.publicKey?.toString() != walletCache) {
            setWalletCache(wallet?.publicKey?.toString())
            onCloseInfoModal();
        }
    }, [wallet])

    // useEffect(() => {
    //     if (auth?.idToken)
    //         fetchWhitelistDetails(auth?.idToken);
    // }, [auth])

    const authorize = async (walletId: any) => {
        enableLoading();
        try {
            await signIn(walletId as string, `` as string);
        }
        catch {
            disableLoading();
            return;
        }
        disableLoading();
    }
    const fetchWhitelistDetails = async (token: any) => {
        const response = await getMyWhitelists(token);
        setState(response);
    }

    const fetchPreorders = async (walletId: any) => {
        const response = await getMyMintOrders(walletId);
        setPreorders(response);
    }

    const fetchTotalMinted = async () => {
        const response = await getPreordersCount();
        setMintedCount(response?.count);
    }

    const preorder = async (wallet: any, amount: number = 1) => {
        const confettiAnimation: any = confetti;
        const playConfettiAnimation = (defaultTimeout = 5000, min: any = null, max: any = null) => {
            confettiAnimation?.start(defaultTimeout, min, max);
            setTimeout(function () {
                confettiAnimation?.stop()
            }, defaultTimeout);
        };

        enableButtonLoading();
        try {
            const { id } = await initPreorder(amount, auth?.idToken);
            const tx = await preOrderCat(wallet, id, amount);
            const response = await processPreorder(id, tx, auth?.idToken);
            play(14);
            playConfettiAnimation();
            setCongratsToastShow(true);
            fetchWhitelistDetails(auth?.idToken);
            fetchPreorders(wallet?.publicKey?.toString());
            setMintedCount(mintedCount + 1);
        }
        catch (e) {
            console.log(e);
            disableButtonLoading();
            return;
        }

        disableButtonLoading();
    }

    const onCloseInfoModal = async () => {
        // fetchTotalMinted();
        enableLoading();
        try {
            const auth: any = await signInViaLedger(wallet);
            await fetchWhitelistDetails(auth?.idToken);
            await fetchPreorders(wallet?.publicKey?.toString());
        }
        catch {
            await wallet.disconnect();
            setWalletCache(null);
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();
    }

    const onLoginViaLedger = async () => {
        enableLoading();
        try {
            const auth: any = await signInViaLedger(wallet);
            await fetchWhitelistDetails(auth?.idToken);
        }
        catch {
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();
    }

    return (
        <>
            <NavBar/>
            <div className="video-wrapper">
                <div className="overlay"></div>
                <VideoBg />
            </div>
            <ToastCongrats
                show={congratsToastShow}
                onClose={() => setCongratsToastShow(false)}>
            </ToastCongrats>
            {
                showInfoModal &&
                <InfoModal
                    preorders={preorders}
                    wallet={wallet}
                    show={showInfoModal}
                    isAuth={!!auth}
                    loading={loading}
                    styleCss={`dark`}
                    onLoginViaLedger={onLoginViaLedger}
                    onHide={() => handleInfoModalClose()}
                />
            }
            <PreOrderContainer
                wallet={wallet}
                onPreorder={(amount: any) => preorder(wallet, amount)}
                state={state}
                preorders={preorders}
                mintedCount={mintedCount}
                onOpenPastMints={handleInfoModalOpen}
                loading={loading}
                buttonLoading={buttonLoading}
            />
        </>
    )
}