export const Partners = () => {
  return (

    <section className="bg-secondary py-5">
      <div className="container py-5 my-5">

        <div className="row pb-3 flex-sm-row-reverse">
          <div className="col-md-5 mb-4 mb-md-0">
            <div className="card bg-dark h-100 p-lg-5">
              <div className="card-body p-lg-2">
                <h2 className="h1 text-light">Official Partners</h2>
                <p className="fs-xl text-light opacity-70 mb-0">Degen Coin Flip has partnered with nearly 50 of the biggest and best projects and people across the Solana Ecosystem.<br/><br/>DCF partnerships bring a fun added utility to communities while helping them generate revenue for their projects.<br/><br/>Our current partners range from Blue Chip NFT projects, Playboy Models, magazines, and even to decentralized exchanges.</p>
              </div>
              <div className="card-footer border-0 w-100 pt-0 p-4 p-lg-2">
                {/* <div className="h2 d-flex align-items-center text-light mb-4">FREE<del className="fs-xl fw-normal opacity-70 ms-2">$49.99</del></div> */}
                {/* <a href="#" className="btn btn-light btn-lg shadow-dark">Become a Partner</a> */}
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="gallery row g-4" data-video="true" data-thumbnails="true">
              <div className="col-md-5">
                <div className="gallery-item rounded-3 mb-4">
                  <img src="https://i.imgur.com/fT6q0Eu.jpg" alt="Generous Robots" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Generous Robots</div>
                </div>
                <div className="gallery-item rounded-3 mb-4">
                  <img src="https://beta.api.solanalysis.com/images/200x200/filters:frames(,0)/https://storage.googleapis.com/feliz-crypto/project_photos/chikarachimps.png" alt="Gallery thumbnail" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Chikara Chimps</div>
                </div>
                {/* <div className="gallery-item rounded-3 mb-4">
                  <img src="https://i.imgur.com/vFycdKC.jpg" alt="Gallery thumbnail" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Jikan</div>
                </div> */}
                {/* <div className="d-flex">
                  <a href="#" className="btn btn-dark btn-sm shadow-dark">SEE MORE</a>
                </div> */}
              </div>
              <div className="col-md-3 col-sm-5">
                <div className="gallery-item rounded-3 mb-4">
                  <img src="https://beta.api.solanalysis.com/images/200x200/filters:frames(,0)/https://storage.googleapis.com/feliz-crypto/project_photos/boryokudragonz.png" alt="Gallery thumbnail" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">BoryokuDragonz</div>
                </div>
                <div className="gallery-item rounded-3 mb-4">
                  <img src="https://beta.api.solanalysis.com/images/200x200/filters:frames(,0)/https://storage.googleapis.com/feliz-crypto/project_photos/dogecapital.png" alt="Gallery thumbnail" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">DogeCapital</div>
                </div>

                <div className="gallery-item rounded-3 mb-4">
                  <img src="https://i.imgur.com/nK0FOVv.jpg" alt="Gallery thumbnail" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Jaylene</div>
                </div>
                <div className="gallery-item rounded-3">
                  <img src=" https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://arweave.net/liJYoNwv6oHlxnZzV4K8Du8QTsHwD0mexOwKkwfjm8I" alt="Gallery thumbnail" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Visionary</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-7">
                <div className="gallery-item rounded-3 mb-4">
                  <img src="https://beta.api.solanalysis.com/images/200x200/filters:frames(,0)/https://storage.googleapis.com/feliz-crypto/project_photos/420ape.jpg" alt="Gallery thumbnail" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Stoned Ape Crew</div>
                </div>
                <div className="gallery-item rounded-3 mb-4">
                  <img src="https://beta.api.solanalysis.com/images/200x200/filters:frames(,0)/https://storage.googleapis.com/feliz-crypto/project_photos/meerkatmillionairescountryclub.jpeg" alt="Gallery thumbnail" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Millionare Meerkats</div>
                </div>
                <div className="gallery-item rounded-3">
                  <img src="https://bafybeibyiozthmcu3uelb6dsj3ydy7bh7zegfplzyfrljc5ovsglrakyjq.ipfs.dweb.link/" alt="Gallery thumbnail" />
                  <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Trippy Kidz</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}