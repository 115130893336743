import { Link, useLocation } from "react-router-dom"

export const NavBar = ({ useDark }: any) => {
    const location = useLocation();

    const DCFButton = () => {
        return (
            <Link to="/degencoinflip" className="text-decoration-none">
                <button className="btn btn-dark nav-bar-button text-decoration-none">
                    DCF <i className="ms-1 bx bxs-coin"></i>
                </button>
            </Link>
        );
    };
    const GalleryButton = () => {
        return (
            <Link to="/gallery" className="text-decoration-none">
                <button className="btn btn-primary nav-bar-button ms-sm-2 ms-1 text-decoration-none">
                    CATS
                </button>
            </Link>
        );
    };
    return (
        <header className={"header navbar navbar-expand position-absolute navbar-sticky py-3 z-index-5 " + (useDark ? "shadow-sm bg-light" : "")}>
            <div className="container px-3">
                <Link to="/" className={location?.pathname !== "/" ? "" : "d-none"}>
                    <img src="https://i.imgur.com/jtNwvf9.png" style={{ maxHeight: '20px' }} alt="Degen Fat Cats" />
                </Link>
                <div id="navbarNav" className="offcanvas offcanvas-end">
                    <div className="offcanvas-header border-bottom">
                        <h5 className="offcanvas-title">Menu</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    {/* <div className="offcanvas-header border-top">
                        <Link to="/mint">
                            Pre mint
                        </Link>
                    </div> */}
                </div>
                <DCFButton />
                <GalleryButton />
            </div>
        </header>
    )
}