import { useState } from "react";
import { VideoModal } from "../modals/VideoModal";


export const openInNewTab = (url: any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const Gallery = () => {

  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
    return (
        <>
        {
          showModal &&
          <VideoModal
            show={showModal}
            walletId={''}
            styleCss={'light'}
            onHide={() => { handleModalClose(); }}
          />
        }
            <section className="py-5 bg-secondary">
                <div className="container my-5 py-5">

                    <div className="d-flex align-items-center justify-content-between pb-4 mb-2">
                        <h2 className="h1 mb-0 mt-5">Made By Community Degens</h2>
                        {/* <a href="#" className="btn btn-dark btn-lg">
                            <i className="bx bx-images fs-4 lh-1 me-2" />
                            See all
                        </a> */}
                    </div>
                    <div className="gallery row g-4" data-video="true" data-thumbnails="true">
                        <div className="col-md-5">
                            <a href="javascript:;" onClick={handleModalOpen} className="gallery-item video-item rounded-3 bg-danger mb-4">
                                <img src="https://i.imgur.com/WNOa1Y1.png" alt="Gallery thumbnail" />
                                <div className="gallery-item-caption gallery-item-caption-none p-4">
                                    <h4 className="mb-1 text-white">Degens 4 Charity</h4>
                                    <p className="mb-0">December 9th, 2021</p>
                                </div>
                            </a>
                            <a href="javascript:;" onClick={() => {}} className="gallery-item cursor-none rounded-3 bg-olive">
                                <img src="https://i.imgur.com/jrBOuZO.png" alt="Gallery thumbnail" />
                                <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Physical Coins</div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-5">
                            <a href="javascript:;" onClick={() => openInNewTab(`https://mixpanel.com/public/KQMKc744sGRZMq3idvsBmK/`)} className="gallery-item  rounded-3 mb-4 bg-olive">
                                <img src="https://i.imgur.com/jQ58MQn.png" alt="Gallery thumbnail" />
                                <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">DCF Mixpanel Stats</div>
                            </a>
                            <a href="javascript:;" onClick={() => openInNewTab(`https://leaderboard.degencoinflip.com/`)} className="gallery-item  rounded-3 mb-4 bg-olive">
                                <img src="https://i.imgur.com/ccMp9Sh.png" alt="Gallery thumbnail" />
                                <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">DCF Leaderboard</div>
                            </a>
                            <a href="javascript:;" onClick={() => openInNewTab(`https://about.degencoinflip.com/`)} className="gallery-item rounded-3 bg-olive" >
                                <img src="https://i.imgur.com/BL2uPno.png" alt="Gallery thumbnail" />
                                <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">About DCF</div>
                            </a>
                        </div>
                        <div className="col-md-4 col-sm-7">
                            <a href="javascript:;" className="gallery-item rounded-3 bg-olive mb-4 cursor-none">
                                <video src="https://giant.gfycat.com/PerkyUncomfortableIridescentshark.mp4" className="inline-video" autoPlay={true} playsInline={true} loop={true} />
                                <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Raffle System</div>
                            </a>
                            <a href="javascript:;" className="gallery-item  rounded-3 bg-olive mb-4 cursor-none" >
                                <img src="https://i.imgur.com/g3PBlIP.png" alt="Gallery thumbnail" />
                                <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Player Card Kickbacks</div>
                            </a>
                            <a href="javascript:;" className="gallery-item  rounded-3 bg-olive cursor-none">
                                <img src="https://i.imgur.com/P8SyLpl.png" alt="Gallery thumbnail" />
                                <div className="gallery-item-caption gallery-item-caption-none fs-sm fw-medium">Deep Discord Integrations</div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}