import { useWallet } from "@solana/wallet-adapter-react";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { AuthorizationContext } from "../../contexts/auth.context";
export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    // console.log(newWindow);
    if (newWindow) newWindow.opener = null
};
const URL = `https://degenfatcats-theta.s3.us-east-2.amazonaws.com/assets-fs/`;

export function ImageModal({ id, styleCss, ...props }) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0 rounded"}>
                <div className="card card-user shadow-lg rounded">
                    <img src={`${URL}${id}.png`} className="img-fluid rounded" ></img>
                </div>
            </Modal.Body>
        </Modal>
    );
}