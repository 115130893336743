import { FC, useState, useEffect } from "react";
import useSWR from "swr";
import { EyeOffIcon } from "@heroicons/react/outline";

import { fetcher } from "../utils/fetcher";

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

// const BASE_PATH = "https://degenfatcats-kappa.s3.amazonaws.com/assets";

const ME_URL = `https://magiceden.io/marketplace/degenfatcats?search=`;
const defaultProfilePicture = `https://i.imgur.com/QaWYGKP.gif?ext=gif`;
const BASE_PATH = "https://degenfatcats.s3.us-east-2.amazonaws.com/assets/unrevealed.png";

export const NftCard = ({ details = null, id = null, imgSrc = null, uri = null, onSelect, onTokenDetailsFetched = () => { }, selected }) => {
  const [fallbackImage, setFallbackImage] = useState(false);
  // const { name, uri } = details?.data ?? {};
  // console.log(details)
  const { data, error } = useSWR(
    // uri || url ? getMetaUrl(details) : null,
    uri,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  // console.log("nftcard");
  // console.log(data);

  useEffect(() => {
    if (!error && !!data) {
      onTokenDetailsFetched(data);
    }
    if (error) {
      setFallbackImage(true);
    }
    // console.log(data);
  }, [data, error]);

  const onImageError = () => {
    console.log('error')
    setFallbackImage(true);
  }
  const { image } = data ?? {};

  return (
    <>
      <div className="col-md-4 mb-2">
        <div className="card card-portfolio">
          <div className="card-img">
            <img src={imgSrc} alt={id}
              onError={(e) => {
                if (e.target.src !== BASE_PATH) { e.target.onerror = null; e.target.src = BASE_PATH; }
              }} />
          </div>
          <div className="card-body">
            <h2 className="h4 mb-3">
              <a href="javascript:;" onClick={() => onSelect()} className="stretched-link">{data?.name ?? `Degen Fat Cat the ${ordinal_suffix_of(id)}`}</a>
            </h2>
            <div className="card-portfolio-meta">
              <div className="row row-cols-3">
                {data?.attributes?.filter(({ value }) => value != 'None')?.map(({ trait_type, value }) => <>
                  <div className="col">
                    <h5 className="h6 mb-0">{value}</h5 >
                    <p className="mb-2"><small>{trait_type}</small></p>
                  </div>
                </>)}
              </div>
              {/* <span className="text-muted">{data?.attributes?.filter(({ value }) => value != 'None')?.map(({ value }) => <>
                {value} /&nbsp;
              </>)}</span> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className={"col-6 pl-0 " + (fallbackImage ? "d-none" : "")}
        onClick={async () => onSelect({ ...details, data: { ...details.data, metadata: data } })}>
        {!fallbackImage || !error ? (
          <div className="container-card">
            <img
              src={imgSrc}
              onError={() => onImageError()} style={{ backgroundColor: `rgb(43, 43, 43)` }}
              className={(selected ? 'card-img-top mb-3 border border-white' : "card-img-top") + (details?.burned ? " opacity-50" : " ")}
            />
            <div className={"badge bottom-left title text-truncate bg-dark text-start" + (details?.burned ? "" : " d-none")} style={{ maxWidth: '80%' }}>EXCHANGED</div>
          </div>
        ) : (
          <div className="card-img-top mb-3">
            <EyeOffIcon className="h-16 w-16 text-white-500" />
          </div>
        )}
      </div> */}
    </>
  );
};