// export const GalleryArtPieces = () => {
//     return (
//         <>

import { NftCard } from "../NftCard"

//             {/* Hero */}
//             <section className="bg-secondary py-5 mb-lg-5">
//                 <div className="container pt-2 pt-lg-4 pt-xl-5">
//                     <h2 className="h1 mb-4 pb-lg-3 pt-lg-1 pb-1 text-center">Popular Posts</h2>
//                 </div>
//                 <div className="pb-lg-5 mb-xl-3">
//                     <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events" data-swiper-options="{
//       &quot;slidesPerView&quot;: 1,
//       &quot;centeredSlides&quot;: true,
//       &quot;loop&quot;: true,
//       &quot;pagination&quot;: {
//         &quot;el&quot;: &quot;.swiper-pagination&quot;,
//         &quot;clickable&quot;: true
//       },
//       &quot;breakpoints&quot;: {
//         &quot;0&quot;: {
//           &quot;slidesPerView&quot;: 1
//         },
//         &quot;576&quot;: {
//           &quot;slidesPerView&quot;: 2
//         },
//         &quot;768&quot;: {
//           &quot;slidesPerView&quot;: 3
//         },
//         &quot;1200&quot;: {
//           &quot;slidesPerView&quot;: 4
//         }
//       }
//     }">
//                         <div className="swiper-wrapper" id="swiper-wrapper-b56d24ac4100dcf55" aria-live="polite" style={{ transitionDuration: '0ms', transform: 'translate3d(-775.333px, 0px, 0px)' }}><div className="swiper-slide h-auto px-2 swiper-slide-duplicate" role="group" aria-label="5 / 7" data-swiper-slide-index={4} style={{ width: '387.667px' }}>
//                             <article className="card border-0 h-100 mx-1">
//                                 <div className="position-relative">
//                                     <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                     <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                         <i className="bx bx-bookmark" />
//                                     </a>
//                                     <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/05.jpg" className="card-img-top" alt="Image" />
//                                 </div>
//                                 <div className="card-body pb-4">
//                                     <div className="d-flex align-items-center justify-content-between mb-3">
//                                         <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Digital</a>
//                                         <span className="fs-sm text-muted">Oct 9, 2021</span>
//                                     </div>
//                                     <h3 className="h5 mb-0">
//                                         <a href="blog-single.html">Inclusive Marketing: Why and How Does it Work?</a>
//                                     </h3>
//                                 </div>
//                                 <div className="card-footer py-4">
//                                     <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/04.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                         Jane Cooper
//                                     </a>
//                                 </div>
//                             </article>
//                         </div><div className="swiper-slide h-auto px-2 swiper-slide-duplicate" role="group" aria-label="6 / 7" data-swiper-slide-index={5} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/06.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Marketing</a>
//                                             <span className="fs-sm text-muted">Apr 2, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">How Agile is Your Forecasting Process?</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/05.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Albert Flores
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div><div className="swiper-slide h-auto px-2 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="7 / 7" data-swiper-slide-index={6} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/07.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Processes &amp; Tools</a>
//                                             <span className="fs-sm text-muted">Sep 3, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">Your Guide to Optimising A JavaScript-enabled Website</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/02.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Ralph Edwards
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div>
//                             {/* Item */}
//                             <div className="swiper-slide h-auto px-2 swiper-slide-active" role="group" aria-label="1 / 7" data-swiper-slide-index={0} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/01.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Web design</a>
//                                             <span className="fs-sm text-muted">May 19, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">5 Bad Landing Page Examples &amp; How We Would Fix Them</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/06.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Annette Black
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div>
//                             {/* Item */}
//                             <div className="swiper-slide h-auto px-2 swiper-slide-next" role="group" aria-label="2 / 7" data-swiper-slide-index={1} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/02.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Digital</a>
//                                             <span className="fs-sm text-muted">Sep 28, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">Why UX Design Matters and How it Affects Ranking</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/01.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Jerome Bell
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div>
//                             {/* Item */}
//                             <div className="swiper-slide h-auto px-2" role="group" aria-label="3 / 7" data-swiper-slide-index={2} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/03.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Business</a>
//                                             <span className="fs-sm text-muted">Sep 16, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">This Week in Search: New Limits and Exciting Features</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/02.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Ralph Edwards
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div>
//                             {/* Item */}
//                             <div className="swiper-slide h-auto px-2" role="group" aria-label="4 / 7" data-swiper-slide-index={3} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/04.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Processes &amp; Tools</a>
//                                             <span className="fs-sm text-muted">12 hours ago</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">Five Effective Lead Generation Techniques For Your Business</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/03.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Esther Howard
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div>
//                             {/* Item */}
//                             <div className="swiper-slide h-auto px-2" role="group" aria-label="5 / 7" data-swiper-slide-index={4} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/05.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Digital</a>
//                                             <span className="fs-sm text-muted">Oct 9, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">Inclusive Marketing: Why and How Does it Work?</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/04.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Jane Cooper
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div>
//                             {/* Item */}
//                             <div className="swiper-slide h-auto px-2" role="group" aria-label="6 / 7" data-swiper-slide-index={5} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/06.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Marketing</a>
//                                             <span className="fs-sm text-muted">Apr 2, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">How Agile is Your Forecasting Process?</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/05.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Albert Flores
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div>
//                             {/* Item */}
//                             <div className="swiper-slide h-auto px-2 swiper-slide-duplicate-prev" role="group" aria-label="7 / 7" data-swiper-slide-index={6} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/07.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Processes &amp; Tools</a>
//                                             <span className="fs-sm text-muted">Sep 3, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">Your Guide to Optimising A JavaScript-enabled Website</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/02.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Ralph Edwards
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div>
//                             <div className="swiper-slide h-auto px-2 swiper-slide-duplicate swiper-slide-duplicate-active" role="group" aria-label="1 / 7" data-swiper-slide-index={0} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/01.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Web design</a>
//                                             <span className="fs-sm text-muted">May 19, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">5 Bad Landing Page Examples &amp; How We Would Fix Them</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/06.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Annette Black
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div><div className="swiper-slide h-auto px-2 swiper-slide-duplicate swiper-slide-duplicate-next" role="group" aria-label="2 / 7" data-swiper-slide-index={1} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/02.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Digital</a>
//                                             <span className="fs-sm text-muted">Sep 28, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">Why UX Design Matters and How it Affects Ranking</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/01.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Jerome Bell
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div><div className="swiper-slide h-auto px-2 swiper-slide-duplicate" role="group" aria-label="3 / 7" data-swiper-slide-index={2} style={{ width: '387.667px' }}>
//                                 <article className="card border-0 h-100 mx-1">
//                                     <div className="position-relative">
//                                         <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
//                                         <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left">
//                                             <i className="bx bx-bookmark" />
//                                         </a>
//                                         <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/blog/03.jpg" className="card-img-top" alt="Image" />
//                                     </div>
//                                     <div className="card-body pb-4">
//                                         <div className="d-flex align-items-center justify-content-between mb-3">
//                                             <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">Business</a>
//                                             <span className="fs-sm text-muted">Sep 16, 2021</span>
//                                         </div>
//                                         <h3 className="h5 mb-0">
//                                             <a href="blog-single.html">This Week in Search: New Limits and Exciting Features</a>
//                                         </h3>
//                                     </div>
//                                     <div className="card-footer py-4">
//                                         <a href="#" className="d-flex align-items-center fw-bold text-dark text-decoration-none">
//                                             <img src="https://silicon.createx.studio/https://silicon.createx.studio/assets/img/avatar/02.jpg" className="rounded-circle me-3" width={48} alt="Avatar" />
//                                             Ralph Edwards
//                                         </a>
//                                     </div>
//                                 </article>
//                             </div></div>
//                         {/* Pagination (bullets) */}
//                         <div className="swiper-pagination position-relative pt-1 pt-sm-3 mt-5 d-xl-none d-flex swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><span className="swiper-pagination-bullet swiper-pagination-bullet-active" tabIndex={0} role="button" aria-label="Go to slide 1" aria-current="true" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 2" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 3" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 4" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 5" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 6" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 7" /></div>
//                         <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /></div>
//                 </div>
//             </section>
//         </>
//     )
// }

const BASE_PATH = "https://degenfatcats-kappa.s3.us-east-2.amazonaws.com";

export const GalleryArtPieces = ({ ids, setModalNum }) => {
    return (
        <>
            <section className="container pb-5 mb-2 mb-md-4 mb-lg-5">
                <div className="row pb-lg-3">
                    {
                        ids?.filter(id => id >= 0 && id < 20000)?.map(id => 
                            <NftCard key={id} id={id} uri={`${BASE_PATH}/${id}.json`} imgSrc={`${BASE_PATH}/${id}.png`} selected={false} onSelect={() => setModalNum(id)} />
                            )
                    }
                </div>
                {/* Pagination */}
                {/* <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li className="page-item active" aria-current="page">
                            <span className="page-link">
                                1
                                <span className="visually-hidden">(current)</span>
                            </span>
                        </li>
                        <li className="page-item">
                            <a href="#" className="page-link">2</a>
                        </li>
                        <li className="page-item">
                            <a href="#" className="page-link">3</a>
                        </li>
                        <li className="page-item">
                            <a href="#" className="page-link">4</a>
                        </li>
                        <li className="page-item">
                            <a href="#" className="page-link">
                                <i className="bx bx-chevron-right mx-n1" />
                            </a>
                        </li>
                    </ul>
                </nav> */}
            </section>

        </>
    )
}