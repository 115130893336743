import { useEffect, useRef } from "react";
import VanillaTilt from "vanilla-tilt";

function Tilt(props: any) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    const current: any = tilt.current;
    VanillaTilt.init(current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const options = {
  max: 2,
  perpective: 1200,
  fullPageListening: true
};

export const Hero = () => {
  return (
    <>
      <section className="position-relative">
        <div className="position-relative bg-dark zindex-4 pt-lg-3 pt-xl-5">
          {/* Text */}
          <div className="container zindex-5">
            <div className="row justify-content-center text-center pb-sm-2">
              <div className="col-xl-8 col-lg-9 col-md-10 py-5 my-5
              ">
                <img src="https://i.imgur.com/LLhRskG.png" className="img-fluid mb-3" style={{ maxWidth: '90px' }} />
                <h1 className="display-4 text-light mb-0">
                  DCF x DFC
                </h1>
                <img
                  className="cursor-pointer double-button mb-4"
                  src="https://i.imgur.com/4RN0yIn.png"
                  alt="double or nothing"
                  width="32%"
                  height="100%" />
                <p className="fs-lg text-light opacity-70 pb-2 pb-sm-0 mb-4 mb-sm-4">
                  Degen Coin Flip is a smart contract that allows users to play Double or Nothing with their Solana tokens. Odds are 50/50 with a 3.5% fee.
                </p>
                <a href="#try" className="btn btn-sm btn-light shadow-dark">
                  TRY A FLIP <span className="ms-2 fas fa-chevron-down mb-1" />
                </a>
              </div>
            </div>
          </div>
          {/* Bottom shape */}
          <div
            className="d-flex position-absolute top-100 start-0 w-100 overflow-hidden mt-n4 mt-sm-n1"
            style={{ color: "#0b0f19" }}
          >
            <div
              className="position-relative start-50 translate-middle-x flex-shrink-0"
              style={{ width: 3788 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={3788}
                height={144}
                viewBox="0 0 3788 144"
              >
                <path
                  fill="currentColor"
                  d="M0,0h3788.7c-525,90.2-1181.7,143.9-1894.3,143.9S525,90.2,0,0z"
                />
              </svg>
            </div>
          </div>
          <div className="d-none d-lg-block" style={{ height: 300 }} />
          <div className="d-none d-md-block d-lg-none" style={{ height: 150 }} />
        </div>
        <div
          className="position-relative zindex-5 mx-auto"
          style={{ maxWidth: 1250 }}
        >
          <div className="d-none d-lg-block" style={{ marginTop: "-300px" }} />
          <div
            className="d-none d-md-block d-lg-none"
            style={{ marginTop: "-150px" }}
          />
          {/* Parallax (3D Tilt) gfx */}
          <Tilt className="tilt-3d" options={options} data-tilt-full-page-listening>
            <img src="https://i.imgur.com/cZT3Tyr.png" alt="Dashboard" />
            <div className="tilt-3d-inner position-absolute top-0 start-0 w-100 h-100">
              <img src="https://i.imgur.com/SfBWHNT.png" alt="Cards" />
            </div>
          </Tilt>
        </div>
        <div
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{ backgroundColor: "rgba(255,255,255,.05)" }}
        />
      </section>
    </>
  )
}