import { useEffect, useState } from 'react';
import Swiper, { SwiperOptions } from 'swiper';
import { payload } from './WhatPeopleSay.json';
const DEFAULT_PFP = "https://i.imgur.com/WtWzHUL.png";

export const openInNewTab = (url: any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const onClickUrl = (url: any) => () => openInNewTab(url)

function getText(html: any) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
}

const Card = ({ name, title, imageUrl, url, messageHtml }: any) => {
    return (<>
        <div className="swiper-slide h-auto pt-4">
            <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                    <span className="btn btn-icon btn-primary bg-twitter-blue border-0 shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4 cursor-pointer" onClick={() => openInNewTab(url)}>
                        <i className="fa-2x fab fa-twitter" />
                    </span>
                    <blockquote className="card-body pb-3 mb-0">
                        <p className="mb-0">{getText(messageHtml)}</p>


                    </blockquote>
                    <div className="card-footer border-0 text-nowrap pt-0">
                        <i className="bx bxs-star text-warning" />
                        <i className="bx bxs-star text-warning" />
                        <i className="bx bxs-star text-warning" />
                        <i className="bx bxs-star text-warning" />
                        <i className="bx bxs-star text-warning" />
                    </div>
                </div>
                <figcaption className="d-flex align-items-center ps-4 pt-4">
                    <img
                        src={imageUrl}
                        width={48}
                        className="rounded-circle"
                        alt={name}
                        onError={(e: any) => {
                            if (e.target.src !== DEFAULT_PFP) { e.target.onerror = null; e.target.src = DEFAULT_PFP; }
                        }}
                    />
                    <div className="ps-3">
                        <h6 className="fs-sm fw-semibold mb-0 text-dark">{name}</h6>
                        <span className="fs-xs text-muted">
                            {title}
                        </span>
                    </div>
                </figcaption>
            </figure>
        </div>
    </>)
}

const PeopleSaySwiper = () => {
    return (

        <div className="swiper people-say-swiper mx-n2">
            <div className="swiper-wrapper">
                {
                    payload?.map((o: any) => (<>
                        <Card
                            name={o.name}
                            title={o.title}
                            imageUrl={o.imageUrl}
                            url={o.url}
                            messageHtml={o.messageHtml}
                        />
                    </>))
                }
            </div>
        </div>
    )
}

export const WhatPeopleSay = () => {
    const [swiper, setSwiper] = useState<Swiper>();
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        const swiperOptions: SwiperOptions =
        {
            slidesPerView: 1,
            spaceBetween: 8,
            loop: true,
            pagination: {
                el: '#swiper-progress',
                type: 'progressbar'
            },
            navigation: {
                prevEl: '#prev-testimonial',
                nextEl: '#next-testimonial'
            },
            breakpoints: {
                "500": {
                    "slidesPerView": 2
                },
                "1000": {
                    "slidesPerView": 2
                },
                "1200": {
                    "slidesPerView": 3
                }
            }
        };
        const swiper = new Swiper('.people-say-swiper', {
            ...swiperOptions
        });

        setSwiper(swiper);

    }, []);

    const onNext = () => {
        // const [a, b]: any = swiper;
        // console.log(a);
        // console.log(b);
        console.log(swiper);
        swiper?.slideNext();
    }

    const onPrev = () => {
        // const [a, b]: any = swiper;
        // console.log(a);
        console.log(swiper);
        // console.log(b);
        swiper?.slidePrev();
    }

    return (
        <>
            <section className="pb-5 bg-secondary">
                <div className='container mb-5 pb-5'>

                    <div className="row pt-2 py-xl-3 mb-5">
                        <div className="col-lg-3 col-md-4">
                            <h2
                                className="h1 text-center text-md-start mx-auto mx-md-0 pt-md-2"
                                style={{ maxWidth: 300 }}
                            >
                                What <br className="d-none d-md-inline" />
                                People Say <br className="d-none d-md-inline" />
                                About DCF:
                            </h2>
                            {/* Slider controls (Prev / next buttons) */}
                            <div className="d-flex justify-content-center justify-content-md-start pb-4 mb-2 pt-2 pt-md-4 mt-md-5">
                                <button
                                    type="button"
                                    id="prev-testimonial"
                                    className="btn btn-prev btn-icon btn-sm me-2"
                                    onClick={onPrev}
                                >
                                    <i className="bx bx-chevron-left" />
                                </button>
                                <button
                                    type="button"
                                    id="next-testimonial"
                                    className="btn btn-next btn-icon btn-sm ms-2"
                                    onClick={onNext}
                                >
                                    <i className="bx bx-chevron-right" />
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <PeopleSaySwiper />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}